import { Builder } from '@builder.io/react'
import React from 'react'
import Slider from 'react-slick'
import LinkButton, { LinkButtonProp } from '../common/button'
import './column-with-images.css'
import CdnImage from 'components/util/CdnImage'
// import CloudinaryImage from 'components/util/CloudinaryImage'

enum Alignment {
  Center = 'center',
  Left = 'left',
  Right = 'right'
}
interface Step {
  // cloudinaryImage: any
  builderImage: any
  imageAltText: string
  descriptionTitleText: string
  descriptionTitleTextAlignment: Alignment
  descriptionText: string
  descriptionTextAlignment: Alignment
  stepBackgroundColor: string
  optimalImgEnable: boolean
}
interface Props {
  backgroundColor: string
  steps: Step[]
  stepDescriptionTitleTextColor: string
  stepDescriptionTextColor: string
  buttonOneProp: LinkButtonProp
  optionalImage: boolean
  cloudinaryOptionalImage: any
  builderOptionalImage: any
  optionalImageAltText: string
  imageProperty: string
  imageOrientation: 'landscape' | 'portrait'
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        justifyContent: 'center',
        zIndex: 2,
        left: '-14px !important',
        top: '50% !imporatant',
        transform: 'translateY(-50%) !important'
      }}
      onClick={onClick}
    />
  )
}

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        justifyContent: 'center',
        zIndex: 2,
        right: '-14px !important',
        top: '50% !imporatant',
        transform: 'translateY(-50%) !important'
      }}
      onClick={onClick}
    />
  )
}

const settingsDesktop = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
}

const settingsMobile = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
}

const ColumnWithImages = ({
  backgroundColor,
  steps,
  stepDescriptionTitleTextColor,
  stepDescriptionTextColor,
  buttonOneProp,
  optionalImage,
  cloudinaryOptionalImage,
  builderOptionalImage,
  optionalImageAltText = '',
  imageProperty,
  imageOrientation
}: Props) => {
  const getAlignmentClass = (textAlignment: any) => {
    if (textAlignment === Alignment.Right) {
      return 'text-right'
    }
    if (textAlignment === Alignment.Center) {
      return 'text-center'
    }
    return 'text-left'
  }

  function getImgOrientation() {
    if (imageOrientation === 'landscape') {
      return 'h-44'
    }
    return 'h-60'
  }

  return (
    <div className="m-auto w-full" style={{ backgroundColor }}>
      <div className="mx-auto max-w-7xl py-5 sm:px-2 sm:py-10 lg:px-4">
        {/* ================= Mobile Slider start */}
        <div className="relative flex justify-center md:hidden">
          <Slider {...settingsMobile} style={{ width: '85%' }}>
            {steps?.map((step: Step, i: number) => (
              <div key={i} className="mx-auto !flex h-full max-w-sm justify-center">
                <div
                  className="mx-2 my-0 flex w-full flex-col"
                  style={{
                    backgroundColor: step?.stepBackgroundColor || '#F9F5F1'
                  }}
                >
                  <div className={`w-full sm:flex-shrink-0 ${getImgOrientation()}`}>
                    <CdnImage
                      className={`${imageProperty}`}
                      url={step.builderImage}
                      alt={step.imageAltText}
                      steps={[400, 300]}
                      style={{
                        width: '400px',
                        height: '280px',
                        maxHeight: '100%',
                        maxWidth: '100%'
                      }}
                    />
                    {/* {step.builderImage ? (
                      <CdnImage
                        className={`${imageProperty}`}
                        url={step.builderImage}
                        alt={step.imageAltText}
                        steps={[400, 300]}
                        style={{
                          width: '400px',
                          height: '280px',
                          maxHeight: '100%',
                          maxWidth: '100%'
                        }}
                      />
                    ) : (
                      <CloudinaryImage
                        className={`${imageProperty}`}
                        publicId={step.cloudinaryImage?.public_id}
                        alt={step.imageAltText}
                        steps={[400, 300]}
                        style={{
                          width: '400px',
                          height: '280px',
                          maxHeight: '100%',
                          maxWidth: '100%'
                        }}
                      />
                    )} */}
                  </div>

                  <div className="flex h-full flex-col justify-between p-6">
                    <div>
                      <p
                        className={` enable-list-styles my-1 break-words text-base lg:text-lg font-semibold font-sans`}
                        dangerouslySetInnerHTML={{ __html: step.descriptionTitleText }}
                        style={{ color: stepDescriptionTitleTextColor }}
                      ></p>
                      <p
                        className={`enable-list-styles mt-2 break-words text-base font-sans ${getAlignmentClass(
                          step.descriptionTextAlignment
                        )}`}
                        dangerouslySetInnerHTML={{ __html: step.descriptionText }}
                        style={{ color: stepDescriptionTextColor }}
                      ></p>
                    </div>
                    {optionalImage && step?.optimalImgEnable && (
                      <div>
                        <div className="mx-auto my-5" style={{ borderBottom: '3px solid black' }}></div>
                        <div className="w-6/12">
                          <CdnImage
                            url={builderOptionalImage}
                            alt={optionalImageAltText}
                            steps={[120]}
                            style={{
                              width: '120px',
                              height: '55px',
                              maxHeight: '100%',
                              maxWidth: '100%'
                            }}
                          />
                          {/* {builderOptionalImage ? (
                            <CdnImage
                              url={builderOptionalImage}
                              alt={optionalImageAltText}
                              steps={[120]}
                              style={{
                                width: '120px',
                                height: '55px',
                                maxHeight: '100%',
                                maxWidth: '100%'
                              }}
                            />
                          ) : (
                            <CloudinaryImage
                              publicId={cloudinaryOptionalImage?.public_id}
                              alt={optionalImageAltText}
                              steps={[120]}
                              style={{
                                width: '120px',
                                height: '55px',
                                maxHeight: '100%',
                                maxWidth: '100%'
                              }} */}
                          {/* /> */}
                          {/* )} */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        {/* ================= Mobile Slider End*/}
        {steps.length < 5 ? (
          <>
            {/* =================Start Desktop Grid*/}
            <div className="hidden md:flex md:justify-center">
              <div className="flex  max-w-2xl justify-center gap-y-10 gap-x-4 px-4 md:w-full md:max-w-none">
                {steps?.map((step: Step, i: number) => (
                  <div
                    key={i}
                    className="flex w-1/4 flex-col"
                    style={{ backgroundColor: step?.stepBackgroundColor || '#F9F5F1' }}
                  >
                    <div className={`w-full sm:flex-shrink-0 ${getImgOrientation()}`}>
                      <CdnImage
                        className={`${imageProperty}`}
                        url={step.builderImage}
                        alt={step.imageAltText}
                        steps={[400, 300]}
                        style={{
                          width: '100%',
                          height: '100%'
                        }}
                      />
                      {/* {step.builderImage ? (
                        <CdnImage
                          className={`${imageProperty}`}
                          url={step.builderImage}
                          alt={step.imageAltText}
                          steps={[400, 300]}
                          style={{
                            width: '100%',
                            height: '100%'
                          }}
                        />
                      ) : (
                        <CloudinaryImage
                          className={`${imageProperty}`}
                          publicId={step.cloudinaryImage?.public_id}
                          alt={step.imageAltText}
                          steps={[400, 300]}
                          style={{
                            width: '100%',
                            height: '100%'
                          }}
                        />
                      )} */}
                    </div>

                    <div className="flex h-full flex-col justify-between p-6">
                      <div>
                        <p
                          className={` enable-list-styles my-1 break-words text-base lg:text-lg font-semibold font-sans ${getAlignmentClass(
                            step.descriptionTitleTextAlignment
                          )}`}
                          dangerouslySetInnerHTML={{ __html: step.descriptionTitleText }}
                          style={{ color: stepDescriptionTitleTextColor }}
                        ></p>
                        <p
                          className={`enable-list-styles mt-2 break-words text-base font-sans ${getAlignmentClass(
                            step.descriptionTextAlignment
                          )}`}
                          style={{ color: stepDescriptionTextColor }}
                          dangerouslySetInnerHTML={{ __html: step.descriptionText }}
                        ></p>
                      </div>
                      {optionalImage && step?.optimalImgEnable && (
                        <div>
                          <div className="mx-auto my-5" style={{ borderBottom: '3px solid black' }}></div>
                          <div className="w-6/12">
                            <CdnImage url={builderOptionalImage} alt={optionalImageAltText} steps={[120]} />
                            {/* {builderOptionalImage ? (
                              <CdnImage url={builderOptionalImage} alt={optionalImageAltText} steps={[120]} />
                            ) : (
                              <CloudinaryImage
                                publicId={cloudinaryOptionalImage?.public_id}
                                alt={optionalImageAltText}
                                steps={[120]}
                              />
                            )} */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* ====================End Desktop Grid*/}
          </>
        ) : (
          <>
            {/* ============ Desktop slider for >=5 card Start =============*/}
            <div className="hidden md:flex md:justify-center">
              <Slider {...settingsDesktop} style={{ width: '95%' }}>
                {steps?.map((step: Step, i: number) => (
                  <div key={i} className="!flex h-full">
                    <div
                      className="mx-2 flex w-full flex-col"
                      style={{ backgroundColor: step?.stepBackgroundColor || '#F9F5F1' }}
                    >
                      <div className={`w-full sm:flex-shrink-0 ${getImgOrientation()}`}>
                        <CdnImage
                          className={`${imageProperty}`}
                          url={step.builderImage}
                          alt={step.imageAltText}
                          steps={[400, 300]}
                          style={{
                            width: '300px',
                            height: '200px',
                            maxHeight: '100%',
                            maxWidth: '100%'
                          }}
                        />
                        {/* {step.builderImage ? (
                          <CdnImage
                            className={`${imageProperty}`}
                            url={step.builderImage}
                            alt={step.imageAltText}
                            steps={[400, 300]}
                            style={{
                              width: '300px',
                              height: '200px',
                              maxHeight: '100%',
                              maxWidth: '100%'
                            }}
                          />
                        ) : (
                          <CloudinaryImage
                            className={`${imageProperty}`}
                            publicId={step.cloudinaryImage?.public_id}
                            alt={step.imageAltText}
                            steps={[400, 300]}
                            style={{
                              width: '300px',
                              height: '200px',
                              maxHeight: '100%',
                              maxWidth: '100%'
                            }}
                          />
                        )} */}
                      </div>
                      <div className="flex h-full flex-col justify-between p-6">
                        <div>
                          <p
                            className={` enable-list-styles my-1 break-words text-base lg:text-lg font-semibold font-sans ${getAlignmentClass(
                              step.descriptionTitleTextAlignment
                            )}`}
                            dangerouslySetInnerHTML={{ __html: step.descriptionTitleText }}
                            style={{ color: stepDescriptionTitleTextColor }}
                          ></p>
                          <p
                            className={`enable-list-styles mt-2 break-words text-base font-sans ${getAlignmentClass(
                              step.descriptionTextAlignment
                            )}`}
                            dangerouslySetInnerHTML={{ __html: step.descriptionText }}
                            style={{ color: stepDescriptionTextColor }}
                          ></p>
                        </div>
                        {optionalImage && step?.optimalImgEnable && (
                          <div>
                            <div className="mx-auto my-5" style={{ borderBottom: '3px solid black' }}></div>
                            <div className="w-6/12">
                              <CdnImage
                                url={builderOptionalImage}
                                alt={optionalImageAltText}
                                steps={[120]}
                                style={{
                                  width: '120px',
                                  height: '55px',
                                  maxHeight: '100%',
                                  maxWidth: '100%'
                                }}
                              />
                              {/* {builderOptionalImage ? (
                                <CdnImage
                                  url={builderOptionalImage}
                                  alt={optionalImageAltText}
                                  steps={[120]}
                                  style={{
                                    width: '120px',
                                    height: '55px',
                                    maxHeight: '100%',
                                    maxWidth: '100%'
                                  }}
                                />
                              ) : (
                                <CloudinaryImage
                                  publicId={cloudinaryOptionalImage?.public_id}
                                  alt={optionalImageAltText}
                                  steps={[120]}
                                  style={{
                                    width: '120px',
                                    height: '55px',
                                    maxHeight: '100%',
                                    maxWidth: '100%'
                                  }}
                                />
                              )} */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            {/* =============== Desktop slider for >=5 card End =============== */}
          </>
        )}
        <div className="flex justify-center px-4 pt-10 text-center" style={{ backgroundColor }}>
          <LinkButton
            buttonColor={buttonOneProp.buttonColor}
            buttonUrl={buttonOneProp.buttonUrl}
            buttonText={buttonOneProp.buttonText}
            loggedInButtonText={buttonOneProp.loggedInButtonText}
            buttonTextColor={buttonOneProp.buttonTextColor}
            isLink={false}
            classList={
              'px-5 py-3 border border-transparent text-base font-medium rounded-full md:py-4 md:text-lg md:px-10 w-full md:w-auto'
            }
          />
        </div>
      </div>
    </div>
  )
}

export default ColumnWithImages
